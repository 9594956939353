let mouseStopTimeout;
let contentElement;

const colors =  [
  '#ff0000',
  '#00ff00'
]




export function updateRemoteCursor(userID, userName, posX, posY, color) {
  
  contentElement = document.getElementById('cameraSubscriberContainer');
  if (!contentElement) return;
  let cursorElement = document.getElementById(getCursorID(userID));
  if (!cursorElement) {
    cursorElement = createCursorElement(userID,color);
  }
  // const svgElem = cursorElement.getElementsByTagName("svg")[0];
  console.log("cursorElement",cursorElement)
  // const text = `↖️ ${userName}`;
  // if (cursorElement != null && cursorElement.innerText !== text) {
  //   cursorElement.innerText = text;
  // }
  console.log(posX,posY);
  cursorElement.style.left = `${posX}%`;
  cursorElement.style.top = `${posY}%`;
  cursorElement.style.fill = `${color}%`;
  cursorElement.style.stroke = "#ffffff";
  cursorElement.style.display = "block";
  // cursorElement.style.cursor = `none`;
  // svgElem.style.fill = color;

  

  
}

function cursotDataGet(e,callback = null){
  clearTimeout(mouseStopTimeout);

  mouseStopTimeout = setTimeout((_) => {
    sendData(e, callback);
  }, 1);
}

export function startCursorListener(callback) {
    
  contentElement = document.getElementById('cameraSubscriberContainer');
  const scrollElement = document.getElementById('cameraSubscriberContainer');
  console.log("contentElement",contentElement)
  console.log("scrollElement",contentElement)
  scrollElement.onmousemove = (e)=>cursotDataGet(e,callback);
}

export function stopCursorListener() {
  const scrollElement = document.getElementById('cameraSubscriberContainer');
  // scrollElement.removeEventListener("onmousemove",cursotDataGet)
  scrollElement.onmousemove = null;
  
}

export function removeCursor(userID) {
  const cursorElement = document.getElementById(getCursorID(userID));
  if (cursorElement) {
    cursorElement.remove();
  }
  
}

export function removeAllCursors() {
  const cursors = document.getElementsByClassName('cursor');
  for (let i = 0; i < cursors.length; i += 1) {
    cursors[i].remove();
  }
}

function sendData(e, callback) {
  if (!contentElement) return;
  // Send data relative to the user's position
  // in the content element
  const rect = contentElement.getBoundingClientRect();
  const x = e.clientX - rect.x;
  const y = e.clientY - rect.y;
  // const xpt = e.clientY - rect.y;
  // const ypt = e.clientY - rect.y;
  // console.log("e.clientX",x, rect.width, (x/rect.width)*100)
  // console.log("e.clientY",y, rect.height, (y/rect.height)*100)
  callback(x, y, rect.width, rect.height, (x/rect.width)*100, (y/rect.height)*100);
}

function changeCursorColor(color) {
  // Encode the SVG with the new fill color
  const svg = `
      <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.23109 21.8329L0.93293 3.11023C0.508124 1.60905 1.97239 0.380386 3.377 1.05941L20.8952 9.52823C22.4824 10.2955 22.3252 12.6047 20.6616 12.9609L13.5842 14.4763C13.0337 14.5942 12.5852 14.9705 12.3735 15.4921L9.6522 22.1989C9.01251 23.7754 6.71112 23.5292 6.23109 21.8329Z" fill="${color}" stroke="white"/>
      </svg>
  `;
  
  // Encode the SVG into a data URL
  const encodedSVG = encodeURIComponent(svg);
  console.log("encodedSVG",encodedSVG);
  // Update the cursor style with the new color
  return `url("data:image/svg+xml,${encodedSVG}")`;

}

export function changeCursorElement(userID,color){
  const existingCursor = getCursorID(userID);
  // alert("changeRemoteCursorElement"+`cursor-${userID}`)
  let ele = document.getElementById(existingCursor);
  if (!ele) {
    ele = createCursorElement(userID,color);
  }
  console.log("ele",color)
  ele.style.background = changeCursorColor(color);
}

function createCursorElement(userID,color) {
  if (!contentElement) return null;
  const ele = document.createElement('div');
  ele.id = getCursorID(userID);
  // ele.innerHTML='<svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.23109 22.3307L0.93293 3.60803C0.508124 2.10685 1.97239 0.878189 3.377 1.55722L20.8952 10.026C22.4824 10.7933 22.3252 13.1025 20.6616 13.4587L13.5842 14.9741C13.0337 15.092 12.5852 15.4683 12.3735 15.9899L9.6522 22.6967C9.01251 24.2732 6.71112 24.027 6.23109 22.3307Z" /></svg>';
  ele.classList.add('cursor');
  ele.style.background = changeCursorColor(color);
  contentElement.appendChild(ele);
  return ele;

}

function getCursorID(userID) {
  return `cursor-${userID}`;
}