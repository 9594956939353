import axios from "axios";
import { API_URL } from '../config/config'
import { getHeaderOptions } from './header';

const API_ENDPOINT = API_URL + '/v1/meeting';
const VONAGEAPI_ENDPOINT = API_URL + '/v1/vonage';

export function getMeetingList(params) {
    return axios.get(`${API_ENDPOINT}/list-meeting`, { headers: getHeaderOptions(), params: params });
}

export function getTechSupportMeetingList(params) {
    return axios.get(`${API_ENDPOINT}/list-meeting-techsupport`, { headers: getHeaderOptions(), params: params });
}

export function callAction(meeting) {
    return axios.post(`${API_ENDPOINT}/call-action`, meeting, { headers: getHeaderOptions() });
}

export function rescheduleCall(id, meeting) {
    return axios.patch(`${API_ENDPOINT}/reschedule-call/${id}`, meeting, { headers: getHeaderOptions() });
}

export function getCallHistory(params) {
    return axios.get(`${API_ENDPOINT}/list-calls`, { headers: getHeaderOptions(), params: params });
}

export function cancelScheduleCall(id, meeting) {
    return axios.patch(`${API_ENDPOINT}/support-user-cancel-call/${id}`, meeting, { headers: getHeaderOptions() });
}

export function machineAdminCallHistory(params) {
    return axios.get(`${API_ENDPOINT}/list-machine-admin-calls`, { headers: getHeaderOptions(), params: params });
}

export function rejectInstantCall(data) {
    return axios.post(`${API_ENDPOINT}/instant-call-end`, data, { headers: getHeaderOptions() });
}

export function instantCallStart(data) {
    return axios.post(`${API_ENDPOINT}/instant-call-start`, data, { headers: getHeaderOptions() });
}

export function rejectInternalCall(data) {
    return axios.post(`${API_ENDPOINT}/end-internal-call`, data, { headers: getHeaderOptions() });
}

export function screenshotUpload(meetingId,data) {
    return axios.post(`${API_ENDPOINT}/upload-screenshot/${meetingId}`, data, { headers: {...getHeaderOptions(), "content-type": "multipart/form-data"} });
}

export function endCall(data) {
    return axios.post(`${API_ENDPOINT}/call-end`, data, { headers: getHeaderOptions() });
}

export function updateSerialNumber(data) {
    return axios.post(`${API_ENDPOINT}/serial-number`, data, { headers: getHeaderOptions() });
}


export function internalCall(data) {
    return axios.post(`${API_ENDPOINT}/internal-call`, data, { headers: getHeaderOptions() });
}

export function internalCallVonage(data) {
    return axios.post(`${VONAGEAPI_ENDPOINT}/vonage-access-token`, data, { headers: getHeaderOptions() });
}

export function initiateInternalCall(data) {
    return axios.post(`${API_ENDPOINT}/start-internal-call`, data, { headers: getHeaderOptions() });
}

export function getOEMUserCallHistory(params) {
    return axios.get(`${API_ENDPOINT}/list-oemuser-call-history`, { headers: getHeaderOptions(), params: params });
}

export function getParticipantsInCall(meetingId){
    return axios.get(`${API_ENDPOINT}/${meetingId}/participants`, { headers: getHeaderOptions() });
}

export function getRecordingStatus(meetingId) {
    return axios.get(`${API_ENDPOINT}/recording-status/${meetingId}`, { headers: getHeaderOptions() });
}

export function sendTranscriptMsg(meetingId, data) {
    return axios.post(`${API_ENDPOINT}/transcript/${meetingId}`, data, { headers: getHeaderOptions() });
}


